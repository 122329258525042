h1,h2,h4,h3,h5,p,div,li {
  font-family: 'Dosis', sans-serif;
}
p{
  font-size: 18px;
}
.fontd{
  font-family: 'Dosis', sans-serif;
  font-size: 50px;
  margin:'0px'
}
.fontd:hover {
  color:yellow;
}
.home{
  background-color: black;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color:white;
}
.top{
  position:absolute;
  top:0;
  font-size: 30px;
  color:white
}
.dashboard{
  background-image: url('https://i.ytimg.com/vi/t0kGRcd-IFY/maxresdefault.jpg');
  min-height: 100vh;
  background-color: black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 30px;
  color:white;
  text-decoration: none;
}
.center{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.shadow{
  -webkit-box-shadow: 7px 6px 19px -4px rgba(122,116,122,1);
-moz-box-shadow: 7px 6px 19px -4px rgba(122,116,122,1);
box-shadow: 7px 6px 19px -4px rgba(122,116,122,1);
}
.skill-img {
  height:110px;
}

@media only screen and (max-width: 600px) {
  .skill-img {
    height:80px;
  }
  
}